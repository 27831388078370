import { HeadingProps } from "../../model/interface";

const Heading = ({ children, className }: HeadingProps) => {
  return (
    <h3 className={`block mb-2 text-wine-100 font-light text-xl ${className}`}>
      {children}
    </h3>
  );
};

export default Heading;
