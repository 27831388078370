import {useEffect, useRef, useState} from "react";
import {CardProps} from "../../model/interface";

const Card = ({content}: CardProps) => {
    const [isFocused, setIsFocused] = useState(false);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (isFocused && buttonRef.current) {
            const formContainer = buttonRef.current?.closest(
                ".form-container"
            ) as HTMLElement | null;

            if (formContainer) {
                const formRect = formContainer.getBoundingClientRect();
                const buttonRect = buttonRef.current.getBoundingClientRect();
                const isOverflowingRight = buttonRect.right > formRect.right;

                if (isOverflowingRight) {
                    buttonRef.current.style.right = `${Math.max(
                        formRect.right - buttonRect.right,
                        0
                    )}px`;
                    buttonRef.current.style.left = "auto";
                }
            }
        }
    }, [isFocused]);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const displayedContent = isFocused ? content : content.slice(0, 2);
    return (
        <div className={`${isFocused ? "h-28" : "h-fit"} w-52 relative`}>
            <button
                type="button"
                ref={buttonRef}
                className="bg-white shadow-md rounded-lg border h-fit border-wine-30 focus:w-96 focus:z-10 focus:absolute focus:ring-2 focus:ring-wine-100 focus:border-b-0 focus:outline-none focus:text-clip"
                onFocus={handleFocus}
                onBlur={handleBlur}
            >
                <div
                    className={`flex flex-col items-start pl-4 p-2 ${isFocused ? "" : "w-52"}`}
                >
                    {displayedContent.map((label, index) =>
                        index === 0 ? (
                            <p
                                key={index}
                                className={`capitalize text-sm font-medium text-wine-120 overflow-y-auto ${
                                    isFocused ? "" : "truncate max-w-[16ch]"
                                }  break-all`}
                            >
                                {label}
                            </p>
                        ) : (
                            <p
                                key={index}
                                className={`capitalize text-sm font-normal text-wine-80 overflow-y-auto ${
                                    isFocused ? "" : "truncate max-w-[16ch]"
                                }  break-all`}
                            >
                                {label}
                            </p>
                        )
                    )}
                </div>
            </button>
        </div>
    );
};

export default Card;
