import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {ModerationData, ModerationDataContextType} from "../model/interface";
import apiService from "../api/services/apiService";
import { useAuthContext } from "./authContext";
import { ErrorDetail } from "../model/interface"

const ModerationDataContext = createContext<ModerationDataContextType>({
  moderationData: undefined,
  setModerationData: () => {},
  fetchError: {hasError: false, errorMessage: ""},
});

const getDefaultData = () => {
  const storedData = localStorage.getItem("moderationData");
  return storedData ? JSON.parse(storedData) : null;
};

export const ModerationDataProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { user } = useAuthContext();
  const [moderationData, setModerationData] = useState<ModerationData>(
    getDefaultData()
  );

  const [fetchError, setFetchError] = useState<ErrorDetail>(
    {
      hasError: false,
      errorMessage: "",
    }
  );

  const fetchData = useCallback(async (): Promise<boolean> => {
    try {
      const response = await apiService.getModerationData();
      if (response.data == null) {
        return false
      }

      localStorage.setItem("moderationData", JSON.stringify(response.data));
      setModerationData(response.data);

      const now = new Date().getTime();
      const expiryTime = now + 24 * 60 * 60 * 1000
      localStorage.setItem("expiryTime", expiryTime.toString());
    } catch (error) {
      if (error instanceof Error) {
        setFetchError({
          hasError: true,
          errorMessage: error.message,
        });
      } else {
        setFetchError({
          hasError: true,
          errorMessage: "Caught an error of unknown type, Please try after sometime",
        });
      }
    }
    return true
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "moderationData",
      JSON.stringify(moderationData)
    );
  }, [moderationData]);

  useEffect(() => {
    const expiryTime = localStorage.getItem("expiryTime")
    const now = new Date().getTime();
    if (expiryTime!= null && now > Number(expiryTime)) {
      localStorage.removeItem("moderationData");
      localStorage.removeItem("expiryTime");
    }

    if (localStorage.getItem("moderationData") === "null") {
      fetchData();
    }
  }, [user, fetchData]);

  return (
    <ModerationDataContext.Provider
      value={{
        moderationData,
        setModerationData,
        fetchError,
      }}
    >
      {children}
    </ModerationDataContext.Provider>
  );
};

export const useModerationDataContext = () => useContext(ModerationDataContext);
