import {UserDetailsProps} from "../../model/interface";
import Card from "../common/card";

const ModerationDetails = ({details}: UserDetailsProps) => {

    return (
        <div className="grid grid-cols-4 ">
            {Object.keys(details).map((detail, index) => (
                <div key={`detail-${index}`} className="flex col-span-1 mt-1  ">
                    <Card
                        key={`{card-${index}}`}
                        content={[detail, details[detail]]}
                    ></Card>
                </div>
            ))}
        </div>
    );
};

export default ModerationDetails;
