import {
    MODERATION_FLAGS,
    ModerationOptions as ModOpts,
    PROFILE_IMAGE_OPTIONS
} from "../../model/constants";
import DropdownInput from "../labelledInput/labelledDropdown";
import {ModerationDetails} from "../../model/interface";
import MultiSelectDropdownInput from "../labelledInput/labelledmultiSelectDropdown";

interface ProfileImagesProps {
    moderationDetails: ModerationDetails;
    images: string[];
    options: string[];
    selectedOptions: Map<string, string[]>;
    setSelectedOptions: (value: Map<string, string[]>) => void;
    handleOnChange: (key: string, value: string | string[] | Map<string, string[]>) => void;
}

const ProfileImages = ({images, moderationDetails, options, selectedOptions, setSelectedOptions, handleOnChange}: ProfileImagesProps) => {
    const handleOptionSelect = (item: string, option: string) => {
        if (selectedOptions.has(item)) {
            let itemOptions = selectedOptions.get(item);
            if (itemOptions) {
                const updatedOptions = itemOptions.includes(option)
                    ? itemOptions.filter((selected) => selected !== option)
                    : [...itemOptions, option];

                selectedOptions.set(item, updatedOptions);
            }
        } else {
            selectedOptions.set(item, [option]);
        }

        setSelectedOptions(selectedOptions);
    };

    return (
        <div className="flex flex-col gap-4 p-1">
            {images.length > 0 ? (
                images.map((item, index) => (
                    <div
                        key={index}
                        className="flex items-start gap-4 border-b border-gray-300 pb-4"
                        >
                        <div className="flex-shrink-0 w-[150px] h-[150px] overflow-hidden">
                            <img
                                src={item}
                                alt={`Image ${index + 1}`}
                                className="w-full h-full object-contain"
                            />
                        </div>
                        <div className="flex-grow">
                            {options.map((option, index) => (
                                <div key={index} className="flex items-center">
                                    <input
                                        id={`inputId:${index}`}
                                        type="checkbox"
                                        value={option}
                                        checked={selectedOptions.get(item)?.includes(option)}
                                        onChange={() => handleOptionSelect(item, option)}
                                        className="mr-2"
                                    />
                                    <label className="cursor-pointer">
                                        {option}
                                    </label>

                                </div>
                            ))}
                        </div>
                    </div>
                ))
            ) : (
                <p>No images available</p>
            )}
        </div>
    );
};

export default ProfileImages;
