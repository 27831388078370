import QuestionDetails from "./questionDetails";
import ModerationDetails from "./moderationDetails";
import Button from "../button/button";
import {useEffect, useRef, useState} from "react";
import {ModerationDetails as ModerationDetailsObj, UserFormDataProps} from "../../model/interface";

import {useVideoPlayerContext} from "../../store/videoPlayerContext";
import LabeledSection from "../common/labelledSection";
import {useModerationDataContext} from "../../store/moderationDataContext";
import {MODERATION_OPTIONS, PROFILE_IMAGE_OPTIONS} from "../../model/constants";
import ModerationOptions from "./moderationOptions";
import RapidFireQuestions from "./rapidFireQuestions";
import ProfileImages from "./profileImages";

const ModerationForm = ({
                            moderationDetails,
                            setModerationDetails,
                            handleInputChange,
                            resetModerationDetails,
                            submitModerationDetails,
                        }: UserFormDataProps) => {
    const {moderationData} =
        useModerationDataContext();
    const {isVideoEnded} = useVideoPlayerContext();

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [profileImagesSelectedOptions, setProfileImagesSelectedOptions] = useState<Map<string, string[]>>(new Map<string, string[]>());
    const [otherReason, setOtherReason] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    // Timestamp for when the form is loaded
    const startTimeRef = useRef<number>(Date.now());
    const firstRender = useRef<boolean>(true);

    useEffect(() => {
        // Set the start time and set default data only on the first render of the component
        if (firstRender.current) {
            startTimeRef.current = Date.now();
            setDefaultData(moderationData);
            firstRender.current = false;
        }
    }, []);

    // Function to preload moderation form using moderation data submitted by previous moderator
    function setDefaultData(moderationData: any) {
        const details: ModerationDetailsObj = {
            looks: "",
            english: "",
            disability_classification:"",
            moderationDetails: [],
            profileImagesModerationDetails: new Map<string, string[]>(),
            otherModerationDetails: "",
            moderationFlags: [],
            moderationTimeTaken: "",
            moderationStartTime: "",
        }

        if (!moderationData || moderationData == "null") {
            return details;
        }

        let data = moderationData;
        if (!data || !data.l1_moderation_data || data.l1_moderation_data.length === 0) {
            return details;
        }

        const l1_data = JSON.parse(data.l1_moderation_data);
        details.looks = l1_data.looks;
        details.english = l1_data.english;
        details.disability_classification = l1_data.disability_classification;
        details.moderationFlags = l1_data.moderationFlags;
        details.otherModerationDetails = l1_data.otherModerationDetails;

        // setting default moderation options which were selected by the previous moderator
        setSelectedOptions(l1_data.moderationDetails);

        // populating 'Other reasons' value if it was checked
        if (details.otherModerationDetails.length > 0) {
            setOtherReason(details.otherModerationDetails);
        }

        // updating the local variable for use in this context
        moderationDetails = details

        // setting default moderation details in moderationView context
        setModerationDetails(details);
    }

    useEffect(() => {
        // Update the start time whenever the form is reset
        startTimeRef.current = Date.now();
    }, []);

    const resetFormData = () => {
        resetModerationDetails();
        setSelectedOptions([]);
        setProfileImagesSelectedOptions(new Map<string, string[]>());
        setOtherReason("");
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (isLoading) return;
        setIsLoading(true)
        const endTime = Date.now();
        const timeTaken = ((endTime - startTimeRef.current) / 1000).toString();  // Time in seconds
        handleInputChange("moderationStartTime", (startTimeRef.current / 1000).toString())
        handleInputChange("moderationTimeTaken", timeTaken);
        handleInputChange("otherModerationDetails", otherReason);
        handleInputChange("profileImagesModerationDetails", profileImagesSelectedOptions);
        handleInputChange("moderationDetails", [...selectedOptions], async (moderationData) => {
            const success = await submitModerationDetails(moderationData, setIsLoading);
            if (success) {
                localStorage.setItem("moderationData", "null");
                localStorage.removeItem("expiryTime");
                window.location.reload();
            }
            resetFormData();
        });
    };

    return (

        <form onSubmit={handleSubmit} className="space-y-5">
            <LabeledSection heading="Fill all the questions:">
                <QuestionDetails
                    moderationDetails={moderationDetails}
                    moderationData={moderationData}
                    handleOnChange={handleInputChange}
                />
            </LabeledSection>

            <ModerationOptions
                options={MODERATION_OPTIONS}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                otherReason={otherReason}
                setOtherReason={setOtherReason}
            />

            {moderationData.rapid_fire_questions.length > 0 &&
                <LabeledSection heading="Rapid Fire Questions:">
                    <RapidFireQuestions
                        details={moderationData.rapid_fire_questions}
                    />
                </LabeledSection>}

            {moderationData.education_details != null && moderationData.education_details.length > 0 &&
                <LabeledSection heading="Education Details:">
                    <ModerationDetails
                        details={JSON.parse(moderationData.education_details)}
                    />
                </LabeledSection>}

            {moderationData.work_details != null && moderationData.work_details.length > 0 &&
                <LabeledSection heading="Work Details:">
                    <ModerationDetails
                        details={JSON.parse(moderationData.work_details)}
                    />
                </LabeledSection>}

            {moderationData.profile_image_urls != null && moderationData.profile_image_urls.length > 0 &&
                <LabeledSection heading="Profile Photos:">
                    <ProfileImages
                        images={moderationData.profile_image_urls}
                        moderationDetails={moderationDetails}
                        options={PROFILE_IMAGE_OPTIONS}
                        selectedOptions={profileImagesSelectedOptions}
                        setSelectedOptions={setProfileImagesSelectedOptions}
                        handleOnChange={handleInputChange}
                    />
                </LabeledSection>}

            {moderationData.disability_text!= null && moderationData.disability_text!= "None" &&
                <LabeledSection heading="Disability Details:">
                     <textarea
                         value={moderationData.disability_text}
                         readOnly
                         style={{
                             resize: 'none',
                             overflow: 'hidden',
                             width: '100%',
                             height: 'auto',
                         }}
                         rows={5}
                     />
                </LabeledSection>}

            <div>
                <Button disabled={(!isVideoEnded && moderationData?.video_url !== "") || isLoading}
                        type="submit">{isLoading ? "Loading..." : "Next"}</Button>
            </div>

        </form>
    );
};

export default ModerationForm;
