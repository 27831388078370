import { useState, useRef, useEffect, KeyboardEvent } from "react";
import { MultiSelectDropdownProps } from "../../model/interface";
import down from "../../assets/down.svg";
import check from "../../assets/check.svg";

const MultiSelectDropdown = ({
  options,
  selectedValue,
  setSelectedValue,
}: MultiSelectDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const updatedSelectedValue = (value: string ) => {
    const index = selectedValue.indexOf(value);
    let newSelectedValues = [...selectedValue];
  
    if (index > -1) {
      newSelectedValues.splice(index, 1); // Remove the value
    } else {
      newSelectedValues.push(value); // Add the value
    }
  
    return newSelectedValues;
  };
  
  const handleItemClick = (value: string) => {
    const newSelectedValues = updatedSelectedValue(value)
    setSelectedValue(newSelectedValues);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex === null || prevIndex === options.length - 1
          ? 0
          : prevIndex + 1
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setFocusedIndex((prevIndex) =>
        prevIndex === null || prevIndex === 0
          ? options.length - 1
          : prevIndex - 1
      );
    } else if (event.key === "Enter" && focusedIndex !== null && isOpen) {
    //   setIsOpen(!isOpen);
      handleItemClick(options[focusedIndex]);
    } else if (event.key === "Enter") {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (focusedIndex !== null && isOpen) {
      const listItems = document.querySelectorAll(".dropdown-item");
      const listItem = listItems[focusedIndex] as HTMLElement | null;
      if (listItem) {
        listItem.focus();
      }
    }
  }, [focusedIndex, isOpen]);

  return (
    <div
      className="rounded-lg relative inline-block m-2 focus:outline-none focus:ring-wine-100 focus:ring-2  "
      onKeyDown={handleKeyDown}
      tabIndex={0}
      ref={dropdownRef}
    >
      <div
        className={`rounded-lg  text-left text-base font-normal flex text-gray-500 border  h-11  border-wine-60  w-64`}
        onClick={toggleDropdown}
      >
        <div className="w-full flex items-center justify-between">
          <input
            id={"dropdown"}
            defaultValue={selectedValue}
            required
            className="pl-4 focus:outline-none focus:disabled:opacity-50 focus:disabled:pointer-events-none"
            onFocus={(e) => {
              e.currentTarget.disabled = true;
            }}
            onBlur={(e) => {
              e.currentTarget.disabled = false;
            }}
          />

          <img className="mr-2" src={down} alt="down" />
        </div>
      </div>
      {isOpen && (
        <div className="absolute z-10 w-64 mt-1 border-wine-80 border-[1px] rounded-lg">
          <ul className="bg-white rounded-lg  border-wine-60 ">
            {/* {options.map((option, index) => (
              <li key={option}>
                <div
                  className={`dropdown-item block px-4 py-2 text-base font-normal text-gray-500 hover:bg-wine-60 hover:text-white hover:ring-wine-100 hover:ring-2 hover:border-b-0 hover:outline-none rounded-md ${
                    focusedIndex === index
                      ? "bg-wine-30 text-white ring-wine-100 ring-2  outline-none"
                      : ""
                  }  `}
                  onClick={() => handleItemClick(option)}
                  tabIndex={-1}
                >
                  {option}
                </div>
              </li>
            ))} */}
            {options.map((option, index) => {
            const isSelected = selectedValue.includes(option);
            return (
              <li key={option}>
                <div
                  className={`dropdown-item block px-4 py-2 text-base font-normal text-gray-500 rounded-md hover:bg-wine-60 hover:text-white hover:ring-wine-100 hover:ring-2 hover:border-b-0 hover:outline-none  ${
                        focusedIndex === index
                          ? "bg-wine-60 text-white ring-wine-100 ring-2  outline-none"
                          : ""
                      }  `}
                  onClick={() => handleItemClick(option)}
                  tabIndex={-1}
                >
                <div className="w-full flex items-center "> 
                  {isSelected ?  <img className="mr-2" src={check} alt="checked" /> : ""}
                  {option}
                </div>
                </div>
              </li>
            );
          })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
