import {useNavigate} from "react-router-dom";
import NavButton from "../button/navButton";
import {Routes} from "../../routes"

const NavBar = () => {
    const navigate = useNavigate();

    function handleClick(endpoint: string) {
        navigate(endpoint);
    }

    return (
        <nav className="ml-auto flex space-x-8">
            <NavButton type="button" onClick={() => handleClick(Routes.ModerateUsers)}>
                Moderation
            </NavButton>
            <NavButton type="button" onClick={() => handleClick(Routes.Users)}>
                Users
            </NavButton>
        </nav>
    );
};
export default NavBar;
