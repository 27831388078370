import Logo from "../common/logo";

import logo from "../../assets/logo.svg";
import Divider from "../common/divider";
import LoginForm from "./loginForm";
import apiService from "../../api/services/apiService";

import {useNavigate} from "react-router-dom";
import {useAuthContext} from "../../store/authContext";
import {getRoleFromString} from "../../utils/roleUtils";
import {Role} from "../../model/constants";
import {ApiResponse, AuthRequest} from "../../model/interface";
import {useState} from "react";
import AlertModal from "../common/alertModal";
import {ResponseData} from "../../model/dashboard_gateway";
import {SupertokenStatus} from "../../model/status";
import {Routes} from "../../routes"

const LoginView = () => {
    const {setUser} = useAuthContext();
    const navigate = useNavigate();
    const [responseError, setResponseError] = useState({
        hasError: false,
        errorMessage: "",
    });

    function generateAuthPayload(email: string, password: string): AuthRequest {
        return {
            "email": email,
            "password": password,
        };
    }

    const setUserDataAndGetRole = (response: ApiResponse<ResponseData>): Role => {
        const user = response.data.response.user
        const role: Role = getRoleFromString(user.role);
        localStorage.setItem("userRole", user.role);
        localStorage.setItem("userId", user.id);
        localStorage.setItem("userEmail", user.email);
        setUser({role: role, user_id: user.id});
        return role;
    }

    const handleLogin = async (email: string, password: string) => {
        try {
            const response = await apiService.login(
                generateAuthPayload(email, password)
            );

            const data = response.data.response;
            if (data.supertoken_status != SupertokenStatus.StatusOk) {
                if (data.supertoken_status == SupertokenStatus.StatusWrongCredentials) {
                    throw new Error("Wrong credentials");
                } else {
                    throw new Error(data.supertoken_status);
                }
            }

            const role = setUserDataAndGetRole(response);
            const endpoint = getEndpoint(role);
            navigate(endpoint);
        } catch (error) {
            if (error instanceof Error) {
                setResponseError({
                    hasError: true,
                    errorMessage: "Login failed: " + error.message,
                });
            } else {
                setResponseError({
                    hasError: true,
                    errorMessage: "An unknown error occurred during login",
                });
            }
        }
    };

    const getEndpoint = (role: string): string => {
        switch (role) {
            case Role.Admin:
            case Role.Developer:
            case Role.L1Moderator:
            case Role.L2Moderator:
                return Routes.Home;
            default:
                return Routes.Unauthorized;
        }
    };

    const handleModalClose = () => {
        setResponseError({hasError: false, errorMessage: ""});
    };

    return (
        <div className="bg-wine-80 w-full max-w-screen-2xl ">
            <div className="flex justify-end mb-4">
                <Logo src={logo}/>
            </div>
            <Divider/>
            <LoginForm onLogin={handleLogin}/>
            {responseError.hasError && (
                <AlertModal
                    onClose={handleModalClose}
                    header="Error While trying to Login"
                >
                    <p>{responseError.errorMessage}</p>
                </AlertModal>
            )}
        </div>
    );
};

export default LoginView;
