import { Role } from "../model/constants";

  export function getRoleFromString(roleStr: string | null): Role {
    if (roleStr === null) {
      return Role.None;
    }
  
    const role = Object.values(Role).find(r => r === roleStr);
    return role ?? Role.None;
  }
  