import {ModerationOptionsProps} from "../../model/interface";
import TextArea from "../labelledInput/textarea";
import {ModerationOptions as ModOpts} from "../../model/constants";

const ModerationOptions = ({
                               options,
                               selectedOptions,
                               setSelectedOptions,
                               otherReason,
                               setOtherReason,
                           }: ModerationOptionsProps) => {


    const handleOptionSelect = (option: string) => {
        const updatedOptions = selectedOptions.includes(option)
            ? selectedOptions.filter((selected) => selected !== option)
            : [...selectedOptions, option];
        setSelectedOptions(updatedOptions);

        if (option == ModOpts.OtherReasons) {
            setOtherReason("");
        }
    };

    const handleOtherReasonChange = (value: string) => {
        setOtherReason(value);
    };


    return (
        <div>
            <div className="grid grid-cols-2 gap-2 mt-4">
                {options.map((option, index) => (
                    <div key={index} className="flex items-center">
                        <input
                            id={`inputId:${index}`}
                            type="checkbox"
                            value={option}
                            checked={selectedOptions.includes(option)}
                            onChange={() => handleOptionSelect(option)}
                            className="mr-2"
                        />
                        <label className="cursor-pointer">
                            {option}
                        </label>

                    </div>
                ))}
                {selectedOptions.includes(ModOpts.OtherReasons) && (
                    <div className="">
                        <TextArea
                            value={otherReason}
                            onChange={handleOtherReasonChange}
                            placeholder="Enter other reasons"
                            className="flex w-100 h-10 text-sm text-center"
                        ></TextArea>
                    </div>
                )}
            </div>

        </div>
    );
};

export default ModerationOptions;
