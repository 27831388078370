// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <App />
// );

// reportWebVitals();

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { performHealthCheck } from "./utils/healthCheck";


(async () => {
  try {
    // const healthCheckResult = await performHealthCheck();
    // if (healthCheckResult.status === 'error') {
    //   console.log(`Health check failed: ${healthCheckResult.error}`)
    //   return;
    // }

    console.log('All dependencies are healthy');
    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );
    root.render(
      <App />
    );
  } catch (error) {
    console.log(`An unexpected error occurred during health check: ${error}`);
  }
})();

reportWebVitals();

