import { ButtonProps } from "../../model/interface";

const Button = ({
  onClick,
  children,
  className,
  type,
  disabled = false,
}: ButtonProps) => {

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`h-12 w-52 bg-wine-100 m-2 p-2 border rounded-full text-slate-200 focus:bg-wine-60 focus:outline-none focus:border-wine-80 focus:text-white  hover:bg-wine-60 hover:outline-none hover:border-wine-80 hover:text-white  ${className} ${disabled ? "opacity-50 cursor-wait" : ""}`}
    >
      {children}
    </button>
  );
};

export default Button;
