import { ModerationDataProvider } from "../../store/moderationDataContext";
import ModerationView from "./moderationView";

const ModerationDataView = () => {
  return (
    <ModerationDataProvider>
      <ModerationView></ModerationView>
    </ModerationDataProvider>
  );
};
export default ModerationDataView;
