import Heading from "./h3heading";
import Divider from "./divider";
import { LabeledSectionProps } from "../../model/interface";

const LabeledSection = ({ heading, children }: LabeledSectionProps) => {
  return (
    <div>
      <Heading>{heading}</Heading>
      <Divider />
      {children}
    </div>
  );
};
export default LabeledSection;
