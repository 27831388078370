import { ButtonProps } from "../../model/interface";

const NavButton = ({ onClick, children, className, type }: ButtonProps) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`text-lg text-gray-80 hover:text-wine-100 mx-2  ${className}`}
    >
      {children}
    </button>
  );
};

export default NavButton;
