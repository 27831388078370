import { useState } from "react";

import Input from "../labelledInput/labelledInput";
import { LoginFormProps } from "../../model/interface";
import Button from "../button/button";

const LoginForm = ({ onLogin }: LoginFormProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const  [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isLoading) return; 
    setIsLoading(true);
    await onLogin(email, password);
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleLogin} className="flex flex-col items-center mt-6 pt-6">
      <div>
        <Input
          isDark={true}
          type="email"
          label="User Email:"
          placeholder="enter your email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          id="email"
        />
      </div>
      <div>
        <Input
          isDark={true}
          type="password"
          label="Password:"
          placeholder="enter your password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          id="password"
        />
      </div>
      <div className="flex justify-center">
        <Button type="submit" disabled = {isLoading}>
          {isLoading? "Logging in..." : "Login"}
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
