import React from "react";
import Header from "../header/header";
import Divider from "../common/divider";

const HomeView = () => {

    function extractFirstNameFromEmail(email: string): string {
        const parts = email.split('@');
        return parts[0].split('.')[0];
    }

    const email = localStorage.getItem("userEmail") ?? "";
    const firstName = extractFirstNameFromEmail(email);

    return (
        <div className="w-full max-w-screen-2xl h-full">
            <Header/>
            <Divider/>
            <h1 className="pt-10 capitalize text-2xl font-normal text-center text-wine-100">Hi {firstName}.</h1>
        </div>
    );
}

export default HomeView;