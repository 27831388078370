import React, { createContext, useContext, useState } from "react";
import { VideoPlayerContextType } from "../model/interface";

const VideoPlayerContext = createContext<VideoPlayerContextType>({
  isVideoEnded: false,
  setIsVideoEnded: () => {},
});

export const useVideoPlayerContext = () => useContext(VideoPlayerContext);

export const VideoPlayerContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isVideoEnded, setIsVideoEnded] = useState(false);

  return (
    <VideoPlayerContext.Provider value={{ isVideoEnded, setIsVideoEnded }}>
      {children}
    </VideoPlayerContext.Provider>
  );
};
