import React, {useState} from "react";
import Input from "../labelledInput/labelledInput";
import Button from "../button/button";
import {ALL_ROLES} from "../../model/constants";
import DropdownInput from "../labelledInput/labelledDropdown";

interface CreateUserFormProps {
    onCreateUser: (email: string, password: string, role: string) => void;
}

interface CreateUserFormData {
    email: string,
    password: string,
    re_password: string,
    role: string
}

const CreateUserForm = ({onCreateUser}: CreateUserFormProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState<CreateUserFormData>({
        email: "",
        password: "",
        re_password: "",
        role: ""
    });


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (isLoading) return;

        // Check if passwords match
        if (formData.password !== formData.re_password) {
            alert("Passwords do not match!");
            return;
        }

        setIsLoading(true);
        onCreateUser(formData.email, formData.password, formData.role);
        setIsLoading(false);
    };

    const handleChange = (id: string, value: string) => {
        setFormData((prev) => ({
            ...prev,
            [id]: value,
        }));
    };


    return (
        <form onSubmit={handleSubmit} className="flex flex-col items-center mt-1 pt-1 space-y-5">
            <h1 className="text-2xl font-normal text-center text-wine-100">Create New User</h1>
            <div>
                <Input
                    isDark={false}
                    type="email"
                    label="Email:"
                    placeholder="enter email"
                    onChange={(e) => handleChange("email", e.target.value)}
                    value={formData.email}
                    id="email"
                />
            </div>
            <div>
                <Input
                    isDark={false}
                    type="password"
                    label="Password:"
                    placeholder="enter password"
                    onChange={(e) => handleChange("password", e.target.value)}
                    value={formData.password}
                    id="password"
                />
            </div>
            <div>
                <Input
                    isDark={false}
                    type="password"
                    label="Re-enter Password:"
                    placeholder="re-enter password"
                    onChange={(e) => handleChange("re_password", e.target.value)}
                    value={formData.re_password}
                    id="re_password"
                />
            </div>
            <div>
                <DropdownInput
                    label="Role:"
                    id="role"
                    onChange={handleChange}
                    value={formData.role}
                    options={ALL_ROLES}
                />
            </div>
            <div className="flex justify-center mt-5">
                <Button type="submit" disabled={isLoading}>
                    {isLoading ? "Creating user..." : "Create User"}
                </Button>
            </div>
        </form>
    );
};

export default CreateUserForm;