import Header from "../header/header";
import Divider from "../common/divider";
import CreateUserForm from "./createUserForm";
import AlertModal from "../common/alertModal";
import {useState} from "react";
import apiService from "../../api/services/apiService";
import {SupertokenStatus} from "../../model/status";
import {SignUpRequest} from "../../model/interface";


export interface UsersViewProps {
    type: string;
}


const UsersView = ({type}: UsersViewProps) => {
    const [response, setResponse] = useState({
        hasError: false,
        errorMessage: "",
        success: false,
        email: ""
    });

    const handleModalClose = () => {
        setResponse({hasError: false, errorMessage: "", success: false, email: ""});
    };

    function generateSignUpParams(email: string, password: string, role: string): SignUpRequest {
        return {
            "email": email,
            "password": password,
            "role": role
        };
    }

    const handleCreateUser = async (email: string, password: string, role: string) => {
        try {
            const params: SignUpRequest = generateSignUpParams(email, password, role)
            const response = await apiService.signup(params);

            const data = response.data.response;
            if (data.supertoken_status != SupertokenStatus.StatusOk) {
                if (data.supertoken_status == SupertokenStatus.StatusFieldError) {
                    throw new Error(data.formFields[0].error);
                } else if (data.supertoken_status == SupertokenStatus.StatusGeneralError) {
                    throw new Error(data.message);
                } else {
                    throw new Error(data.supertoken_status);
                }
            }

            setResponse({hasError: false, errorMessage: data.supertoken_status, success: true, email: email});

        } catch (error) {
            if (error instanceof Error) {
                setResponse({
                    hasError: true,
                    errorMessage: "Sign up failed: " + error.message,
                    success: false,
                    email: ""
                });
            } else {
                setResponse({
                    hasError: true,
                    errorMessage: "An unknown error occurred during sign up",
                    success: false,
                    email: ""
                });
            }
        }
    };

    return (
        <div className=" w-full max-w-screen-2xl  ">
            <Header/>
            <Divider/>
            <CreateUserForm onCreateUser={handleCreateUser}/>
            {
                response.hasError && (
                    <AlertModal
                        onClose={handleModalClose}
                        header="Error While trying to create user"
                    >
                        <p>{response.errorMessage}</p>
                    </AlertModal>
                )}
            {
                response.success && (
                    <AlertModal
                        onClose={handleModalClose}
                        header="User created successfully."
                    >
                        <p>Email: {response.email}</p>
                    </AlertModal>
                )}
        </div>
    );
};
export default UsersView;