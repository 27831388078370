import Logo from "../common/logo";
import NavBar from "../navBar/navBar";
import ModNavBar from "../navBar/moderatorNavBar"
import colouredLogo from "../../assets/colouredLogo.svg";

import {useAuthContext} from "../../store/authContext";
import {ADMIN_ROLE, MODERATOR_ROLES} from "../../model/constants";
import {useNavigate} from "react-router-dom";
import {Routes} from "../../routes"

const Header = () => {
    const {user} = useAuthContext();
    const navigate = useNavigate();

    let showNavBar: boolean = false
    let NNavBar = NavBar
    if (ADMIN_ROLE.includes(user.role)) {
        showNavBar = true;
    } else if (MODERATOR_ROLES.includes(user.role)) {
        showNavBar = true;
        NNavBar = ModNavBar
    }

    const handleSignOut = () => {
        localStorage.removeItem("userId");
        localStorage.removeItem("userRole");
        localStorage.removeItem("moderationData");
        localStorage.removeItem("userEmail");
        navigate(Routes.Login)
    };

    return (
        <header
            className={`sticky flex   ${
                showNavBar
                    ? "  py-2 pe-10 h-25 items-center"
                    : " justify-end"
            } py-2 pe-10 h-20 items-center box_shadow_bot z-30`}
        >
            <Logo
                src={colouredLogo}
            />
            {showNavBar && <NNavBar/>}
            <button
                onClick={handleSignOut}
                className="ml-auto flex items-center space-x-1 text-wine-100 hover:bg-wine-100 hover:text-white px-4 py-2 rounded-full border border-wine-100"
            >
                Sign out
            </button>
        </header>
    );
};
export default Header;
