import LoginView from "./componets/login/loginView";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import ProtectedRoute from "./utils/protectedRoutes";
import {AuthContextProvider} from "./store/authContext";
import {DEVELOPER_ADMIN_ROLES, MODERATOR_ADMIN_ROLES,} from "./model/constants";
import {Routes as DashboardRoutes} from "./routes";
import ModerationDataView from "./componets/moderation/moderationDataProviderView";
import UsersView from "./componets/users/usersView";
import HomeView from "./componets/moderation/moderationHomeView";

function App() {
    return (
        <AuthContextProvider>
            <div className="font-DMSans justify-center flex h-screen">
                <Router>
                    <Routes>
                        <Route path={DashboardRoutes.Login} Component={() => <LoginView/>}/>
                        <Route path={DashboardRoutes.Unauthorized} Component={() => <LoginView/>}/>
                        <Route
                            path={DashboardRoutes.Home}
                            Component={() => (
                                <ProtectedRoute allowedRoles={MODERATOR_ADMIN_ROLES}>
                                    <HomeView/>
                                </ProtectedRoute>
                            )}
                        />
                        <Route
                            path={DashboardRoutes.ModerateUsers}
                            Component={() => (
                                <ProtectedRoute allowedRoles={MODERATOR_ADMIN_ROLES}>
                                    <ModerationDataView/>
                                </ProtectedRoute>
                            )}
                        />
                        <Route
                            path={DashboardRoutes.Users}
                            Component={() => (
                                <ProtectedRoute allowedRoles={DEVELOPER_ADMIN_ROLES}>
                                    <UsersView type={"add-users"}/>
                                </ProtectedRoute>
                            )}
                        />
                        <Route path="*" Component={() => <LoginView/>}/>
                    </Routes>
                </Router>
            </div>
        </AuthContextProvider>
    );
}

export default App;
