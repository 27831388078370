import axios, {AxiosResponse} from 'axios';
import {
    ApiResponse,
    AssignedData,
    AuthRequest,
    ModerationData,
    RequestParams,
    SignUpRequest
} from '../../model/interface';
import {ResponseData} from "../../model/dashboard_gateway";
import {ResponseCodes} from "../../model/status";


const backendBaseUrl = process.env.REACT_APP_BACKEND_URL;



const endpoints = {
    login: `${backendBaseUrl}/auth/sign_in`,
    signup: `${backendBaseUrl}/auth/sign_up`,
    refresh: `${backendBaseUrl}/auth/refresh`,
    getModerationData: `${backendBaseUrl}/api/get_moderation_data`,
    updateModerationData: `${backendBaseUrl}/api/update_moderation_data`,
    plan: `${backendBaseUrl}/`,
};


const apiService = {

    login: async (params: AuthRequest): Promise<ApiResponse<ResponseData>> => {
        try {
            const response: AxiosResponse<ResponseData> = await axios.post(endpoints.login, params, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data.status != ResponseCodes.ResponseCodeOk) {
                throw new Error(response.data.status);
            }

            return {data: response.data, status: response.status, success: true};
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 Unauthorized error and get the response body
                    const message = error.response.data.message;
                    throw new Error(message);
                } else if (error.request) {
                    throw new Error('Network error occurred');
                } else {
                    throw new Error('Failed to make request: ' + error.message);
                }
            } else {
                throw new Error('Failed to login: ' + error);
            }
        }
    },

    signup: async (params: SignUpRequest): Promise<ApiResponse<ResponseData>> => {
        try {
            const response: AxiosResponse<ResponseData> = await axios.post(endpoints.signup, params, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data.status != ResponseCodes.ResponseCodeOk) {
                throw new Error(response.data.status);
            }

            return {data: response.data, status: response.status, success: true};
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 401) {
                    // Handle 401 Unauthorized error and get the response body
                    const message = error.response.data.message;
                    throw new Error(message);
                } else if (error.request) {
                    throw new Error('Network error occurred');
                } else {
                    throw new Error('Failed to make request: ' + error.message);
                }
            } else {
                throw new Error('Failed to sign up: ' + error);
            }
        }
    },

    getModerationData: async (): Promise<ApiResponse<ModerationData | null>> => {
        try {
            const response: AxiosResponse<AssignedData> = await axios.get(endpoints.getModerationData, {
                withCredentials: true,
                params: {
                    "moderator_id": localStorage.getItem("userId"),
                    "moderator_role": localStorage.getItem("userRole")
                }
            });
            if (response.data.user_data === "") {
                return {data: null, status: response.status, success: true};
            }

            const assigned_data: ModerationData = JSON.parse(response.data.user_data)

            return {data: assigned_data, status: response.status, success: true};
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 500) {
                    throw new Error('Failed to get moderation data: ' + error.response?.data.message);
                } else if (error.response && error.response.status === 401) {
                    try {
                        await apiService.refreshTokenWithRetry();
                        return await apiService.getModerationData();
                    } catch (error) {
                        throw new Error('' + error);
                    }
                } else if (error.request) {
                    throw new Error('Network error occurred');
                } else {
                    throw new Error('Failed to make request: ' + error.message);
                }
            }
            throw new Error('Failed to fetch user data: ' + error);
        }
    },

    updateModerationData: async (params: RequestParams): Promise<ApiResponse<any>> => {
        try {
            const response: AxiosResponse<any> = await axios.post(endpoints.updateModerationData, params, {
                headers: {
                    'Content-Type': 'text/plain',
                },
                withCredentials: true,
            });

            return {data: response.data, status: response.status, success: true};
        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 500) {
                    throw new Error('Failed to update moderation data: ' + error.response?.data.message);
                } else if (error.response && error.response.status === 401) {
                    try {
                        await apiService.refreshTokenWithRetry();
                        return await apiService.updateModerationData(params);
                    } catch (error) {
                        throw new Error('' + error);
                    }
                } else if (error.request) {
                    throw new Error('Network error occurred');
                } else {
                    throw new Error('Failed to make request: ' + error.message);
                }
            }
            throw new Error('Failed to update moderation data: ' + error);
        }
    },

    refreshTokenWithRetry: async () => {
        let retries = 1;
        while (retries > 0) {
            try {
                await axios.get(endpoints.refresh, {
                    withCredentials: true,
                });
                return
            } catch (error) {
                retries--;
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
        // If all retries fail, throw an error
        throw new Error('Something went wrong, please try to login again');
    },


};

export default apiService;
