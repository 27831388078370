import {LabelledInputProps} from "../../model/interface";

const Input = ({
                   type,
                   placeholder,
                   label,
                   onChange,
                   id,
                   value,
                   isDark,
                   readOnly = false,
                   className,
                   isRequired = true
               }: LabelledInputProps) => {
    return (
        <div className="space-y-2 ">
            <label
                htmlFor={id}
                className={`block pl-2 text-sm font-medium ${
                    isDark ? "text-white" : "text-gray-700"
                } overflow-x-auto whitespace-nowrap  w-64`}
            >
                {label}
            </label>
            <input
                type={type}
                id={id}
                className={`rounded-lg p-4 m-2 text-sm  border border-wine-60     focus:ring-2 focus:ring-wine-100 focus:border-none focus:outline-none h-11 w-64 ${className}`}
                placeholder={placeholder}
                onChange={onChange}
                autoComplete="current-password"
                value={value}
                required={isRequired}
                readOnly={readOnly}
            />
        </div>
    );
};

export default Input;
