export const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
};

export const haveCommonItems = (arr1: any[], arr2: any[]): boolean => {
    const set1 = new Set(arr1);
    const commonItems = arr2.filter(item => set1.has(item));
    return commonItems.length > 0;
}

export function getAgeFromDOB(dateOfBirth: string) {
    // Function to parse date in "dd mm yyyy" format
    const parseDateDDMMYYYY = (dateStr: string) => {
        const [day, month, year] = dateStr.split(' ').map(Number);
        return new Date(year, month - 1, day); // month is zero-based in JavaScript Date
    };

    // Function to parse date in "yyyy-mm-dd" format
    const parseDateYYYYMMDD = (dateStr: string) => {
        return new Date(dateStr); // ISO format directly used by Date constructor
    };

    // Attempt to parse date
    let dob;
    if (dateOfBirth.includes('-')) {
        dob = parseDateYYYYMMDD(dateOfBirth);
    } else if (dateOfBirth.includes(' ')) {
        dob = parseDateDDMMYYYY(dateOfBirth);
    } else {
        console.error('Unsupported date format');
        return null;
    }

    const today = new Date();

    // Check if the provided dateOfBirth is valid
    if (isNaN(dob.getTime())) {
        console.error('Invalid date format');
        return null;
    }

    // Calculate age
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();

    // If birthday in this year hasn't occurred yet, subtract one year
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
        age--;
    }

    return age;
}
