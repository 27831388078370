interface RapidFireQuestionsProps {
    details: string[];
}

const RapidFireQuestions = ({details = []}: RapidFireQuestionsProps) => {
    return (
        <div className="grid grid-cols-1 gap-4">
            <div className="flex col-span-1 mt-1">
                <ul className="list-disc list-inside">
                    {Array.isArray(details) && details.map((value, index) => (
                        <li key={`detail-${index}`}>{value}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default RapidFireQuestions;
