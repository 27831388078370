import { LabelledMultiSelectDropdownInputProps } from "../../model/interface";
import MultiSelectDropdown from "./multiselectDropdown";

const MultiSelectDropdownInput = ({
  label,
  id,
  onChange,
  multiselectValue = [],
  options,

}: LabelledMultiSelectDropdownInputProps) => {
  return (
    <div>
      <label htmlFor={id} className="block pl-2 text-sm font-medium text-gray-700">
        {label}
      </label>
      <MultiSelectDropdown  selectedValue={multiselectValue}
        setSelectedValue={(value) => {
          if (id) {
            onChange(id, value);
          }
        }}
        options={options}/>
     
    </div>
  );
};

export default MultiSelectDropdownInput;
