import { Navigate } from "react-router-dom";
import { ProtectedRouteprops } from "../model/interface";
import { useAuthContext } from "../store/authContext";
import {Routes} from "../routes"

const ProtectedRoute = ({ allowedRoles, children }: ProtectedRouteprops) => {
  const { user } = useAuthContext();

  if (!allowedRoles.includes(user.role)) {
    return <Navigate to={Routes.Unauthorized} />;
  }
  return children;
};

export default ProtectedRoute;
