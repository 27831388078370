import { VideoLoaderProps } from "../../model/interface";
import { useState, useRef, useEffect } from "react";
import IconButton from "../button/iconButton";
import pauseIcon from "../../assets/pause.svg";
import playIcon from "../../assets/play.svg";
import fullScreenIcon from "../../assets/fullScreen.svg";
import { useVideoPlayerContext } from "../../store/videoPlayerContext";

const VideoLoader = ({ videoUrl }: VideoLoaderProps) => {
  const { setIsVideoEnded } = useVideoPlayerContext();
 const { isVideoEnded} = useVideoPlayerContext();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [src, setSrc] = useState(playIcon);

  const togglePlay = () => {
    if (videoRef.current) {
        if (!videoRef.current.duration) {
            setIsVideoEnded(true);
        }
      if (videoRef.current.paused) {
        videoRef.current.play().then(()=>{
            setIsPlaying(true)
        }).catch((error) => {
          console.error("Error playing the video:", error);
      });
        setSrc(pauseIcon);
      } else if (!videoRef.current.paused && isPlaying) {
        videoRef.current.pause()
        setIsPlaying(false)
        setSrc(playIcon); 
      }
    }
  };

  const toggleFullscreen = () => {
    const video = videoRef.current;
    if (video) {
      if (document.fullscreenElement === video) {
        document.exitFullscreen();
      } else {
        video.requestFullscreen();
      }
    }
  };

  useEffect(() => {
    videoRef.current?.load();
  }, [videoUrl]);

  const handleEnded = () => {
    setIsVideoEnded(true);
    setSrc(playIcon);
  };

  return (
    <div
      className="relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <video
        ref={videoRef}
        className="w-[400px] h-[315px] object-cover"
        title="User Video Loader"
        onEnded={handleEnded}
      >
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <IconButton
        src={src}
        onClick={togglePlay}
        className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-14 h-14 rounded-full p-2 bg-white focus:outline-none ${
          isHovered ? "" : "hidden"
        }`}
      ></IconButton>

        { isVideoEnded && (
            <IconButton
                src={fullScreenIcon}
                onClick={toggleFullscreen}
                className={`absolute top-3/4 left-[90%] transform -translate-x-1/2 -translate-y-1/2 w-8 h-8 rounded-full bg-white focus:outline-none ${
                    isHovered ? "" : "hidden"
                }`}
            ></IconButton>
        )}
    </div>
  );
};

export default VideoLoader;
