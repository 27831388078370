import { IconButtonProps } from "../../model/interface";

const IconButton = ({ onClick, src, className }: IconButtonProps) => {
  return (
    <button
      type="button"
      className={`hover:bg-gray-300   w-8 h-8 flex items-center justify-center ${className}`}
      onClick={onClick}
    >
      <img src={src} alt="icon" />
    </button>
  );
};

export default IconButton;
