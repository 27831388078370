import Heading from "./h3heading";

import IconButton from "../button/iconButton";
import close from "../../assets/close.svg";
import { AlertModalProps } from "../../model/interface";

const AlertModal = ({ header, children, onClose }: AlertModalProps) => {
  return (
    <>
      <div
        className={` fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50`}
      >
        <div className="relative p-4 w-full max-w-2xl max-h-full">
          <div className="relative bg-white rounded-lg shadow ">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t ">
              <Heading className="font-semibold">{header}</Heading>
              <IconButton onClick={onClose} src={close}></IconButton>
            </div>
            <div className="p-4 md:p-5 space-y-4">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlertModal;
