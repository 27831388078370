import React, { createContext, useContext, useState } from "react";
import { AuthContextType, UserInfo } from "../model/interface";
import { getRoleFromString } from "../utils/roleUtils";
import { Role } from "../model/constants";

const AuthContext = createContext<AuthContextType>({
  user: {
    role: getRoleFromString(localStorage.getItem("userRole") || Role.None),
    user_id: localStorage.getItem("userId") || "",
  },
  setUser: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [user, setUser] = useState<UserInfo>({
    role: getRoleFromString(localStorage.getItem("userRole") || Role.None),
    user_id: localStorage.getItem("userId") || "",
  });

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
