import { LabelledDropdownInputProps } from "../../model/interface";
import Dropdown from "./dropdown";
const DropdownInput = ({
  label,
  id,
  onChange,
  value,
  options,
}: LabelledDropdownInputProps) => {
  return (
    <div>
      <label htmlFor={id} className="block pl-2 text-sm font-medium text-gray-700">
        {label}
      </label>
      <Dropdown
        selectedValue={value}
        setSelectedValue={(option) => {
          if (id) {
            if (typeof option === "string") {
            onChange(id, option);
            } else {
              onChange(id, option.label, option.value );
            }
          }
        }}
        options={options}
        />
    </div>
  );
};

export default DropdownInput;
